import { useRouter } from 'next/router';
import { useState, ChangeEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'nhi.shared/dist/components/atoms/Button';
import Input from 'nhi.shared/dist/components/atoms/Input';
import Loader from 'nhi.shared/dist/components/molecules/Loader';
import theme from 'nhi.shared/dist/config/theme';
import { SignInResultCode } from 'nhi.shared/dist/models/sign-in-result-code';
import Credentials from 'nhi.shared/dist/templates/Credentials';

import {
  signIn,
  signInBankId,
  signInStatusChangeAction,
  initializeSithsLoginSession,
  signInSiths
} from '@store/actions/core/application.actions';
import { RootStore } from '@store/reducers/root.reducer';

import { removeUserData } from '../../auth';
import { routes } from '../../config/routes';

interface PropertiesState {
  [key: string]: string;
}

enum LoggingMethod {
  'Default',
  'Credentials',
  'Siths'
}

function Login() {
  const dispatch = useDispatch();
  const router = useRouter();
  const isLoading = useSelector((store: RootStore) => store.application.isLoading);
  const { signInResultCode, signInResultError } = useSelector((store: RootStore) => store.application);
  const defaultLoggingMethod: string =
    router.query.loginMethod?.toString() ?? LoggingMethod[LoggingMethod.Default];
  const loggingMethod: LoggingMethod =
    LoggingMethod[defaultLoggingMethod[0].toUpperCase() + defaultLoggingMethod.slice(1).toLowerCase()] ??
    LoggingMethod.Default;
  const translate = useTranslation().t;
  const [properties, setProperties] = useState<PropertiesState>({});
  useEffect(() => {
    removeUserData();
  }, []);
  useEffect(() => {
    if (loggingMethod == LoggingMethod.Siths) {
      signInHandlers[loggingMethod]();
    }
  }, [loggingMethod]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(signInStatusChangeAction(undefined));
    properties[e.target.name] = e.target.value;
    setProperties({ ...properties });
  };

  const prepareRouterActionPush = (pushTo: string) => {
    const redirectUrl = router.query.redirectUrl ?? router.query.redirecturl;
    const query = redirectUrl ? { redirectUrl } : undefined;
    return () => router.push({ pathname: pushTo, query });
  };

  const signInHandlers = {
    [LoggingMethod.Default]: (e: any) => {
      e.preventDefault();
      if (isLoading) return;

      dispatch(signInBankId(properties['bankid']));
    },
    [LoggingMethod.Credentials]: (e: any) => {
      e.preventDefault();
      if (isLoading || !properties['login'] || !properties['password']) return;

      dispatch(signIn(properties['login'], properties['password']));
    },
    [LoggingMethod.Siths]: () => {
      const { grandidsession } = router.query;
      if (!grandidsession) {
        dispatch(initializeSithsLoginSession());
      } else {
        dispatch(signInSiths(grandidsession as string));
      }
    }
  };

  const renderLoginMethod = () => {
    switch (loggingMethod) {
      case LoggingMethod.Default:
        return (
          <>
            <Input
              onChange={handleChange}
              placeholder="Bank id"
              defaultValue={properties['bankid']}
              shape="rounded"
              type="text"
              style={{ width: '100%' }}
              name="bankid"
            />
          </>
        );
      case LoggingMethod.Credentials:
        return (
          <>
            <Input
              onChange={handleChange}
              placeholder={translate('Login')}
              defaultValue={properties['login']}
              shape="rounded"
              type="text"
              style={{ width: '100%' }}
              name="login"
            />
            <Input
              onChange={handleChange}
              placeholder={translate('Password')}
              shape="rounded"
              type="password"
              style={{ width: '100%', marginTop: '20px' }}
              name="password"
            />
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <Credentials logo="/images/logo.png">
        <div style={{ minHeight: '50px', width: '100%', margin: '0 auto' }}>
          <Loader
            isLoading={isLoading}
            color="white"
            spinnerStyle={{ width: '80px', height: '80px', transform: 'none', marginTop: '40px' }}
          >
            <form onSubmit={signInHandlers[loggingMethod]}>
              {renderLoginMethod()}
              {signInResultCode === SignInResultCode.Error && (
                <div className="error" data-testid="login-error-message">
                  {(signInResultError && translate<any>(signInResultError)) ||
                    translate('Invalid login or password')}
                </div>
              )}
              {loggingMethod !== LoggingMethod.Siths && (
                <Button
                  shape="standard"
                  size="semi-large"
                  color="secondary"
                  style={{ width: '100%', marginTop: '20px' }}
                  type="submit"
                >
                  {translate('Sign in')}
                </Button>
              )}
            </form>
          </Loader>
        </div>
        {loggingMethod !== LoggingMethod.Default && (
          <Button
            shape="standard"
            size="semi-large"
            color="white"
            fontColor={theme.primary}
            icon="/images/bankid-logo.png"
            style={{ width: '100%', marginTop: '70px' }}
            onClick={prepareRouterActionPush(routes.login)}
          >
            {translate('Mobile BankID login')}
          </Button>
        )}
        {loggingMethod !== LoggingMethod.Siths && (
          <Button
            shape="standard"
            size="semi-large"
            color="white"
            fontColor={theme.orange}
            icon="/images/inera-logo.png"
            style={{ width: '100%', marginTop: loggingMethod !== LoggingMethod.Default ? '20px' : '70px' }}
            onClick={prepareRouterActionPush(routes.loginSiths)}
          >
            {translate('SITHS login')}
          </Button>
        )}
      </Credentials>
      <style jsx>
        {`
          .error {
            color: ${theme.error};
            margin-top: 10px;
            text-align: center;
            font-weight: 300;
          }
        `}
      </style>
    </>
  );
}

export default Login;
